import Swiper from 'swiper';

const ratesSliderElement = document.querySelector('.rates__slider');

let ratesSlider = null;

const ratesSliderInit = () => {
  if (window.matchMedia('(max-width: 1199px)').matches) {
    ratesSlider = new Swiper(ratesSliderElement, {
      slidesPerView: 1,
      spaceBetween: 24,

      breakpoints: {
        640: {
          freeMode: true,
          slidesPerView: 'auto',
          spaceBetween: 30
        }
      }
    });
  } else if (ratesSlider) {
    ratesSlider.destroy();
    ratesSlider = null;
  }
}

export default ratesSliderInit;
