import ratesSliderInit from './modules/rates-slider';
import compareTableInit from './modules/compare-table';
import contactsMap from './modules/contacts-map';
import initAnchors from './modules/anchor-scroll';
import initVideoPopup from './modules/video-popup';

ratesSliderInit();
compareTableInit();
initAnchors();
initVideoPopup();

ymaps.ready(contactsMap);

function isTouchDevice() {
  return !!('ontouchstart' in window || navigator.maxTouchPoints);
};

if (isTouchDevice()) {
  document.documentElement.classList.add('touch');
  document.documentElement.classList.remove('no-touch');
} else {
  document.documentElement.classList.add('no-touch');
  document.documentElement.classList.remove('touch');
}
