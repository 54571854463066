const scrollbarWidth = window.innerWidth - document.body.offsetWidth;
const rightFixedElements = [...document.querySelectorAll('.right-fixed')];

export default class Popup {
  constructor(block, openCallback, closeCallback) {
    this._block = block;
    this._wrap = document.querySelector('.popup-wrap');
    this._overlay = this._wrap.querySelector('.popup-wrap__overlay');

    this._onPopupOverlayClick = this._onPopupOverlayClick.bind(this);
    this._onWindowKeydown = this._onWindowKeydown.bind(this);

    if (typeof openCallback === 'function') {
      this._openCallback = openCallback;
    }

    if (typeof closeCallback === 'function') {
      this._closeCallback = closeCallback;
    }
  }

  _onPopupOverlayClick() {
    this.close();
  }

  _onWindowKeydown(evt) {
    if (evt.keyCode === 27) {
      this.close();
    }
  }

  open(cb) {
    this._wrap.classList.add('popup-wrap--visible');
    this._block.classList.add('popup--opened');

    document.body.style.paddingRight = `${scrollbarWidth}px`;
    document.body.classList.add('no-scroll');
    rightFixedElements.forEach((item) => item.style.marginRight = `${scrollbarWidth}px`);

    this._overlay.addEventListener('click', this._onPopupOverlayClick);
    window.addEventListener('keydown', this._onWindowKeydown);

    this._openCallback();
  }

  close() {
    this._wrap.classList.remove('popup-wrap--visible');
    this._block.classList.remove('popup--opened');

    document.body.style.paddingRight = `0`;
    document.body.classList.remove('no-scroll');
    rightFixedElements.forEach((item) => item.style.marginRight = `0`);

    this._overlay.removeEventListener('click', this._onPopupOverlayClick);
    window.removeEventListener('keydown', this._onWindowKeydown);

    this._closeCallback();
  }
}
