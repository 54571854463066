import Popup from './popup';

const videoLink = document.querySelector('.about__video-link');
const videoPopupElement = document.querySelector('.video-popup');
const videoPopupCloseBtn = videoPopupElement.querySelector('.video-popup__close-btn');
const video = videoPopupElement.querySelector('video');

const onPopupOpen = () => {
  if (video) {
    video.play();
  }
};

const onPopupClose = () => {
  if (video) {
    video.pause();
  }
};

const initVideoPopup = () => {
  if (!videoPopupElement) return;

  const videoPopup = new Popup(videoPopupElement, onPopupOpen, onPopupClose);

  if (videoLink) {
    videoLink.addEventListener('click', (evt) => {
      evt.preventDefault();
      videoPopup.open();
    });
  }

  if (videoPopupCloseBtn) {
    videoPopupCloseBtn.addEventListener('click', () => {
      videoPopup.close();
    });
  }

  if (video) {

    video.addEventListener('enterpictureinpicture', () => {
      videoPopup.close();
    });

    video.addEventListener('leavepictureinpicture', () => {
      videoPopup.open();
    });
  }

};

export default initVideoPopup;
