
const compareHeadElement = document.querySelector('.compare__head');
const compareTableElement = document.querySelector('.compare__table');
const compareTableRows = [...document.querySelectorAll('.compare__row')];

const lastRowHeight = compareTableRows[compareTableRows.length - 1].offsetHeight;

const bodyTop = document.body.getBoundingClientRect().top;

let compareTopEdge = compareTableElement.getBoundingClientRect().top - bodyTop;
let compareBottomEdge = compareTopEdge + compareTableElement.offsetHeight - lastRowHeight;

const onWindowScroll = () => {
  if (window.scrollY >= compareTopEdge && window.scrollY <= compareBottomEdge) {
    compareHeadElement.classList.add('compare__head--sticky');
    compareTableElement.style.paddingTop = `${compareHeadElement.offsetHeight}px`;
  } else {
    compareHeadElement.classList.remove('compare__head--sticky');
    compareTableElement.style.paddingTop = 0;
  }
};

const compareTableInit = () => {
  if (window.matchMedia('(max-width: 1199px').matches) {
    window.addEventListener('scroll', onWindowScroll);
  } else {
    window.removeEventListener('scroll', onWindowScroll);
  }
};

export default compareTableInit;
